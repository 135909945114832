import { useMutation } from "@apollo/client";
import {
  InputDate,
  InputFilterable,
  InputMoney,
  InputText,
  MultiInputTemplate,
  SurfaceFieldset,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import _ from "lodash";
import { Fragment, useState } from "react";
import { agencyServicePath, agencyServicesPath } from "routes";
import { If } from "tsx-control-statements/components";

import { translationWithRoot } from "@components/T";

import CreatePreventionAgencyService from "@graphql/mutations/prevention/CreateAgencyService.graphql";

import AgencyServiceForm from "./AgencyServiceForm";
import { FormProps } from "./types";

const { T, t } = translationWithRoot("prevention.agency_services.form");
const { T: TRate, t: tRate } = translationWithRoot(
  "prevention.agency_service_rates"
);

/**
 * Form to create a new agency service
 */
const NewAgencyServiceForm = (formProps: FormProps) => {
  const [error, setError] = useState(null);

  const checkForErrors = sections => {
    if (sections.length === 0) {
      setError(t("errors.add_service_rate"));
    } else {
      setError(null);
    }
  };

  const [createPreventionAgencyService] = useMutation(
    CreatePreventionAgencyService,
    {
      onCompleted: mutationData => {
        window.location.href = agencyServicePath(
          mutationData.createPreventionAgencyService.agencyService.id
        );
      },
    }
  );

  const { formState, setFormAttribute } = useBintiForm(null, {
    initialValue: { agencyServiceRates: [] },
  });

  const multiUpdate = value => {
    if (!_.isEqual(value, formState.agencyServiceRates)) {
      setFormAttribute("agencyServiceRates")(value);
    }
  };

  const handleSubmit = () => {
    if (formState.agencyServiceRates.length === 0) {
      setError(t("errors.add_service_rate"));
      return;
    }

    createPreventionAgencyService({
      variables: {
        input: {
          agencyServiceRates: formState.agencyServiceRates.map(rate => ({
            ...rate,
            billingStructure: rate.billingStructure.value,
          })),
          areasOfCoverage: formState.areasOfCoverage?.map(({ value }) => value),
          evidenceBasedPracticeId: formState.evidenceBasedPractice?.value,
          fundingSources: formState.fundingSources?.map(({ value }) => value),
          isFfpsa: formState.isFfpsa,
          name: formState.name,
          providerAgencyId: formState.providerAgency?.value,
          referringAgencyId: formState.referringAgency?.value,
          serviceTypes: formState.serviceTypes?.map(({ value }) => value),
        },
      },
    });
  };

  return (
    <AgencyServiceForm
      {...formProps}
      onSubmit={handleSubmit}
      setFormAttribute={setFormAttribute}
      formState={formState}
      cancelHref={agencyServicesPath()}
    >
      <SurfaceFieldset title={<T t="add_rate_section_title" />}>
        <MultiInputTemplate
          error={error}
          checkForErrors={checkForErrors}
          addSectionText={tRate("add")}
          hideTitle
          defaultValues={{}}
          onChange={multiUpdate}
          onDelete={() => {}}
          removeSectionText={tRate("remove")}
          data-testid={"agency-service-rate-inputs"}
          sectionInputs={({ getMultiInputProps }) => {
            // collect the billingStructure props here so we can use it
            // for the conditional below
            const billingStructure = getMultiInputProps({
              id: "billingStructure",
            })?.value;

            return (
              <Fragment>
                <InputText
                  {...getMultiInputProps({ id: "label" })}
                  label={<TRate t="label" />}
                  required
                />
                <InputText
                  {...getMultiInputProps({ id: "code" })}
                  label={<TRate t="code" />}
                  description={<TRate t="code_description" />}
                  required={false}
                />
                <InputDate
                  {...getMultiInputProps({ id: "startDate" })}
                  label={<TRate t="start_date" />}
                  required
                />
                <InputDate
                  {...getMultiInputProps({ id: "endDate" })}
                  label={<TRate t="end_date" />}
                  required
                />
                <InputFilterable
                  {...getMultiInputProps({ id: "billingStructure" })}
                  label={<TRate t="billing_structure" />}
                  values={formProps.billingStructures}
                  required
                />
                <If
                  condition={
                    billingStructure?.value !== "variable_one_time_fee"
                  }
                >
                  <InputMoney
                    {...getMultiInputProps({ id: "amount" })}
                    label={<TRate t="amount" />}
                    required
                  />
                </If>
                <If condition={billingStructure?.value === "fee_per_service"}>
                  <InputText
                    {...getMultiInputProps({ id: "sessionsPerService" })}
                    label={<TRate t="sessions_per_service" />}
                    type="number"
                    required
                  />
                </If>
              </Fragment>
            );
          }}
          startingValues={[]}
          title={<TRate t="title" />}
        />
      </SurfaceFieldset>
    </AgencyServiceForm>
  );
};

export default NewAgencyServiceForm;
