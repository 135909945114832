import { useMutation, useReactiveVar } from "@apollo/client";
import { Button, InputDropdown, Modal, Upload } from "@heart/components";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";

import { translationWithRoot } from "@components/T";
import useErrorModal from "@components/prevention/tabs/documents_and_requirements_tab/ErrorModal";
import { queryVariablesVar } from "@components/shared/BintiApolloProvider";

import RegisterAgencyServiceReferralDocuments from "@graphql/mutations/prevention/RegisterAgencyServiceReferralDocuments.graphql";
import AgencyServiceReferralRequirementReconciliation from "@graphql/queries/prevention/AgencyServiceReferralRequirementReconciliation.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import useShrine from "@lib/useShrine";

import styles from "./UploadRefDocsButton.scss";

const { t } = translationWithRoot("prevention.documents_and_requirements");

/**
 * Creates upload button and alert modal for
 * uploading documents for an agency service referral
 */
const UploadRefDocsButton = ({ agencyServiceReferralId, recipients = [] }) => {
  const queryVariables = useReactiveVar(queryVariablesVar);

  const [registerDocuments, { data, error, called, reset }] = useMutation(
    RegisterAgencyServiceReferralDocuments,
    {
      refetchQueries: [
        {
          query: AgencyServiceReferralRequirementReconciliation,
          variables: queryVariables,
        },
      ],
    }
  );
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [ErrorModal, setErrorCode] = useErrorModal();
  const [hidden, setHidden] = useState(true);

  const onUpload = async files => {
    setSelectedFiles(files);
  };

  const recipientsOptions = recipients.map(recipient => ({
    value: recipient.id,
    label: recipient.fullName,
  }));

  const { prepareShrineFiles, ShrineErrorAlert } = useShrine();

  const onSubmit = async () => {
    if (!selectedFiles.length) {
      // Nothing to upload, returning early
      return;
    }

    setHidden(true);
    setSelectedFiles([]);
    const shrineFiles = await prepareShrineFiles(selectedFiles);

    // Not all files were uploaded, maybe due to an error.
    if (shrineFiles.length !== selectedFiles.length) {
      setErrorCode("generic_error");
    } else {
      registerDocuments({
        variables: {
          agencyServiceReferralId,
          files: shrineFiles,
          recipientId: selectedRecipient?.value,
        },
      });
    }
  };

  useEffect(() => {
    if (called) {
      if (error) {
        setErrorCode("generic_error");
      } else if (
        data?.preventionRegisterAgencyServiceReferralDocuments?.errors?.length
      ) {
        const virusError =
          data.preventionRegisterAgencyServiceReferralDocuments.errors.find(
            gqError => gqError.message.match(/virus/i)
          );

        if (virusError) {
          setErrorCode("virus_detected");
        } else {
          setErrorCode("generic_error");
        }
      }

      reset();
    }
  }, [called, error, setErrorCode, data, reset]);

  const UploadModal = () => (
    <Modal
      key={"upload-modal"}
      hidden={hidden}
      onCancel={() => {
        setSelectedFiles([]);
        setHidden(true);
      }}
      title={t("upload_documents")}
      onSubmit={onSubmit}
    >
      <div className={styles["modal-body"]}>
        <If condition={selectedFiles.length}>
          <ul>
            {selectedFiles.map(file => (
              <li key={file.name}>{file.name}</li>
            ))}
          </ul>
        </If>
        <If condition={!selectedFiles.length}>
          <Upload onUpload={onUpload} multiple={true} />
        </If>
        <div className={styles["recipient-input"]}>
          <InputDropdown
            values={recipientsOptions}
            label={"Related to"}
            onChange={recipient => setSelectedRecipient(recipient)}
          />
        </div>
      </div>
    </Modal>
  );

  const UploadBtn = () => (
    <Button onClick={() => setHidden(false)} type={"button"} key={"upload-btn"}>
      {t("upload_documents")}{" "}
    </Button>
  );

  return {
    UploadButton: UploadBtn,
    UploadBtnModals: () => (
      <Fragment>
        <ShrineErrorAlert />
        <UploadModal />
        <ErrorModal />
      </Fragment>
    ),
  };
};

UploadRefDocsButton.propTypes = {
  agencyServiceReferralId: BintiPropTypes.ID,
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string,
    })
  ),
};

export default UploadRefDocsButton;
