import { useMutation, useQuery } from "@apollo/client";
import { Button, Icons, InputText } from "@heart/components";
import classnames from "classnames";
import { compact, isEmpty, uniq } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";

import Spinner from "@components/Spinner";

import CreateApplicationFormRequirementTemplate from "@graphql/mutations/CreateApplicationFormRequirementTemplate.graphql";
import CreateUploadTypeRequirementTemplate from "@graphql/mutations/CreateUploadTypeRequirementTemplate.graphql";
import SetApplicationStageTemplateFormSlugs from "@graphql/mutations/SetApplicationStageTemplateFormSlugs.graphql";
import SetApplicationStageTemplateUploadTypeSlugs from "@graphql/mutations/SetApplicationStageTemplateUploadTypeSlugs.graphql";
import ApplicationStageTemplateQuery from "@graphql/queries/ApplicationStageTemplate.graphql";

import { isTestEnvironment } from "@lib/environment";
import preventDefault from "@lib/preventDefault";

import styles from "./ApplicationTemplateDesigner.module.scss";
import Form from "./Form";
import PresentNotPresent from "./PresentNotPresent";
import UploadType from "./UploadType";

const OtherSections = ({ applicationStageTemplate }) => (
  <ul className={styles.applicantStageOtherSections}>
    <li>
      References section{" "}
      <PresentNotPresent
        value={applicationStageTemplate.hasReferencesSection}
      />
    </li>
    <li>
      Other adults section{" "}
      <PresentNotPresent
        value={applicationStageTemplate.hasOtherAdultsInHomeSection}
      />
    </li>
    <li>
      Children in home section{" "}
      <PresentNotPresent
        value={applicationStageTemplate.hasChildrenInHomeSection}
      />
    </li>
  </ul>
);

OtherSections.propTypes = {
  applicationStageTemplate: PropTypes.object.isRequired,
};

/**
 * Display for an application stage template associated with an application
 * template. This display includes basic identifying information about the
 * stage (e.g. headline) and the required documents that may be provided
 * by end users on the stages generated from this template.
 *
 * Allows:
 * 1. Adding and removing forms and upload types as requirements from the
 *    stage.
 * 2. Reordering the forms and templates on the stage.
 * 3. Clicking through to a page to edit the stage template.
 *
 * The adding and reordering functionality are managed via drag and drop.
 * The ids for these droppable areas are injected as props.
 */
const ApplicationStageTemplate = ({
  id,
  applicationTemplate,
  formDroppableId,
  uploadTypeDroppableId,
  showFormsInStages,
  showUploadTypesInStages,
}) => {
  const templateFormSlugs = uniq(
    compact(
      applicationTemplate.requirementTemplates.map(
        template => template.form?.slug
      )
    )
  );

  const templateUploadTypeSlugs = uniq(
    compact(
      applicationTemplate.requirementTemplates.map(
        template => template.uploadType?.slug
      )
    )
  );

  const { data, loading } = useQuery(ApplicationStageTemplateQuery, {
    variables: { id },
  });

  const [createApplicationFormRequirementTemplate] = useMutation(
    CreateApplicationFormRequirementTemplate
  );
  const onAddFormToTemplate = formSlug => () => {
    createApplicationFormRequirementTemplate({
      variables: {
        applicationTemplateId: applicationTemplate.id,
        formSlug,
      },
    });
  };

  const [createUploadTypeRequirementTemplate] = useMutation(
    CreateUploadTypeRequirementTemplate
  );
  const onAddUploadTypeToTemplate = uploadTypeSlug => () => {
    createUploadTypeRequirementTemplate({
      variables: {
        applicationTemplateId: applicationTemplate.id,
        uploadTypeSlug,
      },
    });
  };

  const [setApplicationStageTemplateFormSlugs] = useMutation(
    SetApplicationStageTemplateFormSlugs
  );

  const [setApplicationStageTemplateUploadTypeSlugs] = useMutation(
    SetApplicationStageTemplateUploadTypeSlugs
  );

  const onRemoveForm = formSlug => () => {
    setApplicationStageTemplateFormSlugs({
      variables: {
        applicationStageTemplateId: applicationStageTemplate.id,
        formSlugs: applicationStageTemplate.forms
          .map(form => form.slug)
          .filter(slug => slug !== formSlug),
      },
    });
  };

  const onRemoveUploadType = uploadTypeSlug => () => {
    setApplicationStageTemplateUploadTypeSlugs({
      variables: {
        applicationStageTemplateId: applicationStageTemplate.id,
        uploadTypeSlugs: applicationStageTemplate.uploadTypes
          .map(uploadType => uploadType.slug)
          .filter(slug => slug !== uploadTypeSlug),
      },
    });
  };

  const [addFormSlug, setAddFormSlug] = useState("");
  const onAddFormSlug = preventDefault(() => {
    setApplicationStageTemplateFormSlugs({
      variables: {
        applicationStageTemplateId: applicationStageTemplate.id,
        formSlugs: applicationStageTemplate.forms
          .map(form => form.slug)
          .concat([addFormSlug]),
      },
    }).then(() => {
      setAddFormSlug("");
    });
  });

  const [addUploadTypeSlug, setAddUploadTypeSlug] = useState("");
  const onAddUploadTypeSlug = preventDefault(() => {
    setApplicationStageTemplateUploadTypeSlugs({
      variables: {
        applicationStageTemplateId: applicationStageTemplate.id,
        uploadTypeSlugs: applicationStageTemplate.uploadTypes
          .map(uploadType => uploadType.slug)
          .concat([addUploadTypeSlug]),
      },
    }).then(() => {
      setAddUploadTypeSlug("");
    });
  });

  if (loading) {
    return <Spinner />;
  }

  const { applicationStageTemplate } = data;

  const stageType = applicationStageTemplate.type.replace("StageTemplate", "");
  const isNormal = stageType === "Normal";

  return (
    <div
      className={classnames(styles.formList, styles.applicantStage)}
      data-testid="stage-template"
    >
      <div
        className={styles.applicantStageHeadline}
        data-testid="stage-template-headline"
      >
        {applicationStageTemplate.headlineTranslations.en} (Stage number:
        {applicationStageTemplate.stageNumber})
      </div>
      <div className={styles.applicantStageType}>{stageType}</div>
      {isNormal && (
        <OtherSections applicationStageTemplate={applicationStageTemplate} />
      )}
      <div className={styles.applicantStageRequirements}>
        {applicationStageTemplate.forms && showFormsInStages && (
          <Droppable droppableId={formDroppableId}>
            {provided => (
              <div ref={provided.innerRef}>
                <div className={styles.thingTypeHeader}>Stage Forms</div>
                {applicationStageTemplate.forms.map((form, index) => (
                  <Draggable
                    key={form.slug}
                    draggableId={`form:stage-${applicationStageTemplate.stageNumber}:${form.slug}`}
                    index={index}
                  >
                    {draggableProvided => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                      >
                        <Form
                          form={form}
                          onRemove={onRemoveForm(form.slug)}
                          dragHandleProps={draggableProvided.dragHandleProps}
                          isMissingFromTemplate={
                            !templateFormSlugs.includes(form.slug)
                          }
                          onAddToTemplate={onAddFormToTemplate(form.slug)}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {isEmpty(applicationStageTemplate.forms) && (
                  <div className={styles.dndEmpty}>Drag here to add a form</div>
                )}
                {provided.placeholder}
                <If condition={isTestEnvironment()}>
                  {/* Cypress flakes a lot with drag n drop - this gives us an out */}
                  <InputText
                    label="Add form slug"
                    value={addFormSlug}
                    onChange={setAddFormSlug}
                  />
                  <Button
                    variant="secondary"
                    onClick={onAddFormSlug}
                    icon={Icons.Plus}
                    description="Add form slug"
                  />
                </If>
              </div>
            )}
          </Droppable>
        )}
        {applicationStageTemplate.uploadTypes && showUploadTypesInStages && (
          <Droppable droppableId={uploadTypeDroppableId}>
            {provided => (
              <div ref={provided.innerRef}>
                <div className={styles.thingTypeHeader}>Stage Upload Types</div>
                {applicationStageTemplate.uploadTypes.map(
                  (uploadType, index) => (
                    <Draggable
                      key={uploadType.slug}
                      draggableId={`uploadType:stage-${applicationStageTemplate.stageNumber}:${uploadType.slug}`}
                      index={index}
                    >
                      {draggableProvided => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                        >
                          <UploadType
                            uploadType={uploadType}
                            onRemove={onRemoveUploadType(uploadType.slug)}
                            dragHandleProps={draggableProvided.dragHandleProps}
                            onAddToTemplate={
                              templateUploadTypeSlugs.includes(
                                uploadType.slug
                              ) && onAddUploadTypeToTemplate(uploadType.slug)
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                )}
                {isEmpty(applicationStageTemplate.uploadTypes) && (
                  <div className={styles.dndEmpty}>
                    Drag here to add an upload type
                  </div>
                )}
                {provided.placeholder}
                <If condition={isTestEnvironment()}>
                  {/* Cypress flakes a lot with drag n drop - this gives us an out */}
                  <InputText
                    label="Add uploadType slug"
                    value={addUploadTypeSlug}
                    onChange={setAddUploadTypeSlug}
                  />
                  <Button
                    onClick={onAddUploadTypeSlug}
                    description="Add uploadType slug"
                    icon={Icons.Plus}
                  />
                </If>
              </div>
            )}
          </Droppable>
        )}
      </div>
    </div>
  );
};

ApplicationStageTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  applicationTemplate: PropTypes.object.isRequired,
  formDroppableId: PropTypes.string,
  uploadTypeDroppableId: PropTypes.string,
  showUploadTypesInStages: PropTypes.bool.isRequired,
  showFormsInStages: PropTypes.bool.isRequired,
};

export default ApplicationStageTemplate;
