import { useMutation } from "@apollo/client";
import { Modal } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import _ from "lodash";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import CreateConditionalRequirementMutation from "@graphql/mutations/CreateConditionalRequirement.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import ConditionalRequirementForm from "./ConditionalRequirementForm";

const { t } = translationWithRoot("conditional_requirements");

const initialFormState = {};

/**
 * Form for creating an conditional requirement.
 */
const ConditionalRequirementCreateModal = ({ applicationId, onClose }) => {
  const { formState, setFormAttribute } = useBintiForm(initialFormState);

  const [createConditionalRequirement, { loading }] = useMutation(
    CreateConditionalRequirementMutation,
    {
      onCompleted: onClose,
    }
  );

  const onSubmit = preventDefault(() =>
    createConditionalRequirement({
      variables: {
        input: {
          applicationId,
          ..._.omit(formState, "__typename"),
        },
      },
    })
  );

  return (
    <Modal
      title={t("title")}
      onSubmit={onSubmit}
      onCancel={onClose}
      submitting={loading}
    >
      <ConditionalRequirementForm
        applicationId={applicationId}
        formState={formState}
        setFormAttribute={setFormAttribute}
      />
    </Modal>
  );
};

ConditionalRequirementCreateModal.propTypes = {
  applicationId: BintiPropTypes.ID,
  onClose: PropTypes.func,
};

export default ConditionalRequirementCreateModal;
