// import { gql, useQuery } from "@apollo/client";
import { Button } from "@heart/components";
import { Fragment, useState } from "react";

import ConditionalRequirementCreateModal from "@components/conditional_requirements/ConditionalRequirementCreateModal";

import BintiPropTypes from "@lib/BintiPropTypes";

/**
 * Button to add an conditional requirement.
 */
const AddConditionalRequirementButton = ({ applicationId }) => {
  const [showConditionalRequirementModal, setShowConditionalRequirementModal] =
    useState(false);

  return (
    <Fragment>
      <Button
        variant="secondary"
        onClick={() => setShowConditionalRequirementModal(true)}
      >
        Add Conditional Requirement
      </Button>
      <If condition={showConditionalRequirementModal}>
        <ConditionalRequirementCreateModal
          applicationId={applicationId}
          onClose={() => setShowConditionalRequirementModal(false)}
        />
      </If>
    </Fragment>
  );
};

AddConditionalRequirementButton.propTypes = {
  applicationId: BintiPropTypes.ID,
};

export default AddConditionalRequirementButton;
