import { InputEthnicities, InputRadioGroup } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";
import ProtectedTribes from "@components/intake_protected_tribes/ProtectedTribes";

import { PROTECTED_TRIBES, NON_RECOGNIZED_TRIBES } from "@root/constants";

const { t: protectedTribesT } = translationWithRoot(
  "views.common.protected_tribe",
  {
    escapeJavascriptRoot: true,
  }
);
const { t } = translationWithRoot("agency_human.form.ethnicities_and_icwa");

const EthnicityAndICWASection = ({
  ethnicitiesValid,
  setEthnicitiesValid,
  formState,
  setFormAttribute,
  setFormState,
}) => (
  <Fragment>
    <InputEthnicities
      selectedOptions={formState.ethnicities}
      onChange={(newEthnicities, { valid }) => {
        setFormAttribute("ethnicities")(newEthnicities);
        setEthnicitiesValid(valid);
      }}
      error={ethnicitiesValid ? "" : t("please_specify_ethnicity")}
    />
    <InputRadioGroup
      label={t("has_person_been_asked")}
      value={formState.askedAboutProtectedTribe}
      values={[
        { label: protectedTribesT("answer_yes"), value: true },
        { label: protectedTribesT("answer_no"), value: false },
      ]}
      onChange={askedAboutProtectedTribe =>
        setFormState({
          ...formState,
          askedAboutProtectedTribe,
          tribes: askedAboutProtectedTribe
            ? formState.tribes
            : {
                isProtectedTribeMember: null,
                tribeNames: [],
                tribeIdentification: null,
              },
        })
      }
    />
    <If condition={formState.askedAboutProtectedTribe}>
      <ProtectedTribes
        isRequired={true}
        enrolledMemberQuestionLabel={t("are_they_enrolled_in_tribe")}
        anyTribeQuestionLabel={protectedTribesT("t_is_member_of_any_tribe")}
        nonRecognizedTribeQuestionLabel={protectedTribesT(
          "t_is_non_recognized_tribe_member"
        )}
        tribeOptions={PROTECTED_TRIBES}
        nonRecognizedTribeOptions={NON_RECOGNIZED_TRIBES}
        inputValues={formState}
        values={formState.tribes}
        onChange={setFormAttribute("tribes")}
      />
    </If>
  </Fragment>
);
EthnicityAndICWASection.propTypes = {
  ethnicitiesValid: PropTypes.bool.isRequired,
  setEthnicitiesValid: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  setFormAttribute: PropTypes.func.isRequired,
  /** We need to use setFormState for the case where we're setting
   * more than one field in formState as updating state is asynchronous
   * and using setFormAttribute twice in a row creates a race condition.
   */
  setFormState: PropTypes.func.isRequired,
};

export default EthnicityAndICWASection;
